<template>
  <b-card title="Funded">
    <b-tabs>
      <!-- <b-tab
        active
        @click="$router.push('/add-ngos')"
      >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Ngos</span>
        </template>
        <Addngos />
      </b-tab> -->
      <b-tab @click="$router.push('/all-ngos')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>  Funded</span>
        </template>
       
        <!-- <AllAccreditation></AllAccreditation> -->
      </b-tab>
      <b-tab @click="$router.push(`/add-project-ngos/${id}`)">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>   Project</span>
        </template>
       
        <!-- <AllAccreditation></AllAccreditation> -->
      </b-tab>
      <b-tab @click="$router.push(`/add-logo-ngos/${id}`)">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>   Logo</span>
        </template>
       
        <!-- <AllAccreditation></AllAccreditation> -->
      </b-tab>
    
    
      <b-tab @click="$router.push(`/add-faq-ngos/${id}`)">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>   Faq</span>
        </template>
       
        <!-- <AllAccreditation></AllAccreditation> -->
      </b-tab>
      <b-tab @click="$router.push(`/add-price-ngos/${id}`)">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>   Price</span>
        </template>
       
        <!-- <AllAccreditation></AllAccreditation> -->
      </b-tab>
      <b-tab @click="$router.push(`/add-long-price-ngos/${id}`)">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>   Diploma Price</span>
        </template>
       
        <!-- <AllAccreditation></AllAccreditation> -->
      </b-tab>
      <b-tab @click="$router.push(`/add-schedule-ngos/${id}`)">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span> add Schedule </span>
        </template>
       
        <!-- <AllAccreditation></AllAccreditation> -->
      </b-tab>
      <b-tab @click="$router.push(`/list-schedule-ngos/${id}`)">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span> List Schedule </span>
        </template>
       
        <!-- <AllAccreditation></AllAccreditation> -->
      </b-tab>
      <!-- <b-tab @click="$router.push(`/add-other-schedule-ngos/${id}`)">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span> add Other Schedule </span>
        </template>
       
      
      </b-tab> -->
      <b-tab @click="$router.push(`/add-long-schedule-ngos/${id}`)">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span> add Diploma Schedule </span>
        </template>
       
        <!-- <AllAccreditation></AllAccreditation> -->
      </b-tab>
      <b-tab @click="$router.push(`/list-long-schedule-ngos/${id}`)">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span> List Diploma Schedule </span>
        </template>
       
        <!-- <AllAccreditation></AllAccreditation> -->
      </b-tab>
      <b-tab @click="$router.push(`/add-full-discount-ngos/${id}`)">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span> Full Discount </span>
        </template>
       
        <!-- <AllAccreditation></AllAccreditation> -->
      </b-tab>
      <!-- <b-tab  @click="$router.push('/add-specific-price-country-page')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>New Specific Prices</span>
        </template>
       

      </b-tab> -->
      <router-view />
 
    </b-tabs>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'
import AllNgos from './AllNgos.vue'

export default {
  components: {
    BCard,

    BTabs,
    BTab,

    AllNgos,
  },
  data() {
    return {
id:""
    }
    
  },
  created(){
    this.id=localStorage.getItem('ngoId')
  }
}
</script>
